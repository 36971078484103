window.setHeight= function( ) {
    var footerHeight = parseInt($("footer").height( ));
    var navHeight = parseInt($("nav").height( ));

    if (! navHeight ) {
        navHeight = parseInt($(".group-give-nav").height( ));
    }

    var docHeight = parseInt($(document).height( ));
    var screenHeight = 0;

    if (footerHeight > navHeight) {
        screenHeight = ((footerHeight + navHeight) + docHeight);

    } else {
        screenHeight = ((navHeight + footerHeight) + docHeight);
    }

    $("#wrapper > .container").css("min-height", docHeight + "px");

    $("#wrapper").css("min-height", screenHeight + "px");


    $(window).on("resize", function ( ) {

        $(".modal").height($(document).height( ));

        var footerHeight = parseInt($("footer").height( ));
        var navHeight = parseInt($("nav").height( ));
        var docHeight = parseInt($(document).height( ));
        var screenHeight = 0;

        if (footerHeight > navHeight) {
            screenHeight = ((footerHeight + navHeight) + docHeight);

        } else {
            screenHeight = ((navHeight + footerHeight) + docHeight);
        }

        $("#wrapper > .container").css("min-height", parseInt(docHeight) + "px");
    });
}

window.adjustContainerHeight=function() {

    // $('.main-content').css('min-height', 0);

    var docHeight = parseInt($(document).outerHeight());
    var conHeight = parseInt($('.main-content').outerHeight());
    var navHeight = parseInt($("nav").height());
    var footerHeight = parseInt($("footer").height());
    var sidebarLeft = $("#sidebar-left").outerHeight();
    var sidebarRight = $("#sidebar-right").outerHeight();

    conHeight = Math.max(conHeight, sidebarLeft, sidebarRight);
    var minHeight = 0;
    var pad = 40;


    if (docHeight > (conHeight + navHeight + footerHeight + pad)) {
        minHeight = docHeight - footerHeight - navHeight - pad;
    } else {
        minHeight = conHeight;
    }
    // $('.main-content').css('min-height', minHeight);
}


jQuery(document).ready(function ($) {

    $('html').on('click', function (e) {
        if (typeof $(e.target).data('original-title') == 'undefined' && !$(e.target).parents().is('.popover.in')) {
            $('[data-original-title]').popover('hide');
        }
    });




});

/**
 * This function is what allows the Module Headers to reload the module on click.
 * When the module is loaded, this function is called to re bind functionality.
 */
window.bindToModHeader=function() {
    $(".module-header").unbind().on("click", function () {
        reloadModule(this);
    });
}

/**
 *
 * Looks for a parent element with the class of dashboard-module, then
 * the module for that parent will be reloaded.
 *
 * @param callingElement
 */
window.reloadModule=function(callingElement) {
    var parentLoadModule = $(callingElement).parents(".dashboard-module");
    var path = parentLoadModule.data("load-path");

    if (path != "") {

        // parentLoadModule.find(".contents").html('<div class="text-align-center"><img src="https://res.cloudinary.com/project-world-impact/image/upload/c_scale,w_100/v1687465593/system/loading2.gif" alt="Loading..."></div>');

        parentLoadModule.find(".contents").load(path, function (response, status, xhr) {
            if (status === "success") {

            }


            if (status === "error") {
                parentLoadModule.html("<div class='text-align-center text-danger'><div><i class='fa fa-4x fa-exclamation-triangle' aria-hidden='true'></i></div><div>Something went wrong</div></div>");
            }
        });
    }
}

window.AddURLParameter=function(paramName, paramValue) {
    if(document.location.href.indexOf('?') > -1) {
        var url = "&" + paramName + "=" + paramValue;
    }
    else {
        var url = "?" + paramName + "=" + paramValue;
    }

    window.history.pushState( {} , '', url );
}

window.GetURLParameter=function (sParam) {

    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');

    for (var i = 0; i < sURLVariables.length; i++) {

        var sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }

    }

    return false;
}

/**
 * Logs a record to the user site visit stats.
 *
 * @param type
 * @param value
 */
window.logPageVisit=function(type, value) {
    var statCountCap = 20;

    // Fetch the values from localStorage.
    var pwiSiteVisitStats = JSON.parse(window.localStorage.getItem('pwiSiteVisitStats'));
    if (!pwiSiteVisitStats) {
        pwiSiteVisitStats = {};
    }

    // The current stat index already exists, so we can manipulate the list as-is.
    if (pwiSiteVisitStats.hasOwnProperty(type)) {
        // If the value already exists in the list, remove it so we can bump it to the top of the order.
        if (pwiSiteVisitStats[type].indexOf(value) > 0) {
            pwiSiteVisitStats[type].splice(pwiSiteVisitStats[type].indexOf(value), 1);
        }

        // Push the value into the array
        pwiSiteVisitStats[type].push(value);
    }

    // The stat index does not exist. Create it.
    else {
        pwiSiteVisitStats[type] = [value];
    }

    // Check if we have exceeded the maximum stat count size
    if (pwiSiteVisitStats[type].length > statCountCap) {
        pwiSiteVisitStats[type].splice(0, pwiSiteVisitStats[type].length - statCountCap);
    }

    // Set the value in localStorage
    window.localStorage.setItem('pwiSiteVisitStats', JSON.stringify(pwiSiteVisitStats));
};
